<template>
  <div class="home" v-cloak>
    <swiper v-if="slides.length>0" :options="swiperOption" :style="{height:swiperHeight}">
      <swiper-slide v-for="(item,index) in slides" :key="index">
        <img ondragstart="return false" :src="item.img">
        <div class="con" :class="item.textAlign" :style="item.style">
          <h2>{{ item.name }}</h2>
          <ul>
            <li v-for="(text,index) in item.desc" :key="index">{{ text }}</li>
          </ul>
        </div>
      </swiper-slide>

      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="panels" :style="{height:panelHeight}">
      <div class="panel" v-for="(item,index) in panels" :key="index">
        <img :src="item.img">
        <h3>{{ item.h }}</h3>
        <p>{{ item.p }}</p>
        <a target="_blank" :href="item.to"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  data() {
    return {
      swiperHeight: this.setHeight(2.85),
      panelHeight: this.setHeight(7.1),
      swiperOption: {
        loop: true,
        autoplay: { disableOnInteraction: false },
        speed: 800,
        allowTouchMove: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      panels: [
        {
          img: "static/image/home/panel_1.png",
          h: this.$t("home.panels[0].h"),
          p: this.$t("home.panels[0].p"),
          to: "https://item.jd.com/4223077.html"
        },
        {
          img: "static/image/home/panel_2.png",
          h: this.$t("home.panels[1].h"),
          p: this.$t("home.panels[1].p"),
          to: "https://item.jd.com/7603056.html"
        },
        {
          img: "static/image/home/panel_3.png",
          h: this.$t("home.panels[2].h"),
          p: this.$t("home.panels[2].p"),
          to:
            "https://detail.tmall.com/item.htm?spm=a1z10.3-b.w4011-18285664052.77.682a73414us2f7&id=571398488275&rn=7e2d9492f8b1bd5cec6b1fc83df582fe&abbucket=4"
        }
      ],
      slides: [
        {
          name: this.$t("home.slides[0].name"),
          img: "static/image/home/banner_4.png",
          desc: this.$t("home.slides[0].desc"),
          textAlign: "left",
          style: "top:30%;left:18%;"
        },
        {
          name: this.$t("home.slides[1].name"),
          img: "static/image/home/banner_2.png",
          desc: this.$t("home.slides[1].desc"),
          textAlign: "left",
          style: "top:35%;left:15%;"
        },
        {
          name: this.$t("home.slides[2].name"),
          img: "static/image/home/banner_3.png",
          desc: this.$t("home.slides[2].desc"),
          textAlign: "right",
          style: "top:20%;left:48%;"
        }
      ]
    };
  },
  components: {
    swiper,
    swiperSlide
  },
  mounted() {
    window.onresize = () => {
      this.swiperHeight = this.setHeight(2.85);
      this.panelHeight = this.setHeight(7.1);
    };
  },
  methods: {
    setHeight(scale) {
      return (window.innerWidth > 1200 ? window.innerWidth : 1200) / scale + "px";
    }
  }
};
</script>

<style scoped lang="stylus">
.swiper-container {
  img {
    display: block;
    height: 100%;
  }

  .swiper-button-prev, .swiper-button-next {
    margin-top: -36px;
    width: 72px;
    height: 72px;
    background-size: 100%;
    outline: none;
    transition: opacity 1s;
    opacity: 0;
  }

  .swiper-button-prev {
    background-image: url('left.png');
  }

  .swiper-button-next {
    background-image: url('right.png');
  }

  &:hover {
    .swiper-button-prev, .swiper-button-next {
      opacity: 1;
    }
  }

  .con {
    position: absolute;
    color: #fff;

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    h2 {
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: normal;
      letter-spacing: 5px;
    }

    ul {
      font-size: 14px;
      line-height: 2;

      li {
        cursor: default;
      }
    }
  }
}

.panels {
  margin: 5px 0;
  width: 100%;
  letter-spacing: 0;

  .panel {
    position: relative;
    width: 33.3333333%;
    height: 100%;
    float: left;
    background-color: #eee;
    border-right: 5px solid #fff;
    overflow: hidden;

    &:last-child {
      border-right: none;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img, h3, p, a {
      position: absolute;
    }

    img {
      top: 10%;
      left: 10%;
      height: 80%;
      transition: 0.8s;
    }

    h3 {
      top: 35%;
      left: 50%;
      width: 190px;
      font-size: 1.2em;
      opacity: 0.7;
      letter-spacing: 0;
    }

    p {
      top: 50%;
      left: 50%;
      width: 190px;
      opacity: 0.7;
      font-size: 14px;
    }

    a {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
    }
  }
}
</style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const language = navigator.language.substr(0, 2);

const zh = {
  nav: {
    watch: '运动手表',
    watchs: ['FERACE 3', 'Jeep智能全境界','Jeep全境界 F02'],
    bracelet: '健康手环',
    bracelets: ['D3 彩屏手环'],
    position: '定位产品',
    positions: ['4G定位电话 T1'],
    download: 'APP下载',
    news: '相关新闻',
    about: '关于我们',
    bbs: '产品论坛'
  },
  footer: {
    nav: {
      about: '关于我们',
      serve: '服务支持',
      weibo: '官方微博',
      weixin: '官方微信',
      jingdong: '京东自营店'
    },
    copyright:
      'Copyright © 2014-2018 HOIN Corporation, All Right Reserved ∣粤ICP备15063876号-2',
    privacyPolicy: '隐私政策'
  },
  home: {
    panels: [
      {
        h: 'FERACE 3',
        p: '智能运动手表'
      },
      {
        h: 'Jeep',
        p: '智能全境界腕表'
      },
      {
        h: 'FERACE',
        p: '4G定位电话 T1'
      }
    ],
    slides: [
      {
        name: 'Jeep 智能全境界腕表',
        desc: [
          '专业运动手表，支持跑步、徒步、游泳、骑行等专业运动模式',
          '支持4G全网通，独立插卡',
          '支持支付、导航等、语音、在线音乐、在线翻译等扩展智能化应用',
          '高通骁龙Wear 2100芯片',
          '1.30英寸360*360 AMOLED屏幕',
          '460mAh聚合物锂电池'
        ]
      },
      {
        name: 'FERACE 3 智能运动手表',
        desc: [
          '专业运动手表，支持跑步、徒步、游泳、登山、骑行等专业运动模式',
          '支持 4G 全网通，独立插卡',
          '支持 4G 穿戴版支付宝、在线音乐等运动场景下的智能应用',
          '高通骁龙 Wear 2100 芯片',
          '1.39 英寸 360*360 AMOLED 屏幕',
          '665mAh 聚合物锂电池'
        ]
      },
      {
        name: 'D 3 彩屏手环',
        desc: [
          '消息实时提醒：来电提醒、微信提醒、QQ提醒',
          '24小时心率检测',
          '专业睡眠监测',
          '卡路里、计步、距离、血压、血氧、久坐提醒、查找手机、抬腕亮屏、闹钟、秒表、遥控拍照',
          '0.96 英寸高清彩屏'
        ]
      }
    ]
  },
  about: {
    title_1: '关于我们',
    title_2: '联系我们',
    introduction: [
      '和盈互联是一家运动健康高科技公司，致力于用科技使人健康生活。用户通过智能可穿戴设备，科学精准的监测自己的运动、睡眠、心率、慢病等健康数据；同时，用户基于公司iService平台提供的AI辅助分析和健康指导，形成个性化的运动和饮食健康解决方案，且通过iService平台进行运动和饮食自我管理，最终构建用户个性化的运动健康生活方式。',
      '公司核心研发和运营团队主要来自海信、光聚医疗、百度、清华伯克利、加拿大七橡树，在运动健康智能硬件、手表安卓系统深度开发及运动算法上有核心技术积累，在运营商渠道和保险健康渠道有多年合作沉淀。公司未来发展重点：为用户提供客制化、专业化的实时健康监测的智能穿戴产品，及用户可进行健康生活自我管理的iSERVICE健康管理平台。',
      '2017年6月，公司成功推出了全球首款4G全网通智能运动手表及iSports 1.0 核心运动算法，解决了用户抛开手机去运动问题，引领智能穿戴从1.0时代进入2.0时代。2018年6月，公司与Jeep联合推出全球最薄4G全网通智能运动手表及iSports 2.0核心运动算法，解决了用户专业运动的问题。2018年7月，公司与平安好医生达成战略合作，为平安好医生提供运动健康智能硬件及硬件接入服务，开启运动健康智能硬件+保险公司健康保险业务合作新模式。2018年12月，公司成功中标中国移动首款自主品牌4G智能运动手表定制项目，开启与中国移动的深度战略合作。',
      '公司是美国高通、阿里云计算、科大讯飞、平安好医生、飞亚达、清华伯克利、加拿大七橡树战略技术合作伙伴；是Jeep、飞亚达、中国电信、中国移动、京东、平安好医生战略品牌合作伙伴；是京东、中国电信、中国移动、中国联通、迪信通、飞亚达等战略渠道合作伙伴；是中石油物流信息化认证资质供应商。',
      '愿景：成为全球顶级的运动健康高科技公司',
      '使命：用科技使人健康生活',
      '价值观：诚信、进取、合作、和赢'
    ],
    contact: [
      {
        name: '服务热线',
        text: '400-966-0001'
      },
      {
        name: '市场商务合作',
        text: 'yibx@hoinnet.com'
      },
      {
        name: '销售渠道合作',
        text: 'zhuxj@hoinnet.com'
      },
      {
        name: '地址',
        text: '深圳市宝安区宝安大道4004号旭生大厦16层'
      }
    ]
  },
  download: {
    apps: [
      {
        name: 'FERACE 运动',
        text:
          'FERACE 运动手表配套应用。可管理运动数据、下载表盘及各种手表应用。'
      },
      {
        name: 'JeepWatches',
        text: 'Jeep 运动手表配套应用，可管理运动数据、下载表盘及各种手表应用。'
      },
      {
        name: 'FERACE 健康',
        text: 'FERACE 系列手环配套应用，可进行设备设置，及运动健康数据的管理。'
      },
      {
        name: 'FERACE 定位',
        text: 'FERACE 定位电话配套应用，可进行设备设置，远程定位。'
      }
    ]
  },
  news: { readmore: '阅读全文' },
  privacy: {
    t_1: '尊敬的用户：',
    t_2:
      '深圳市和盈互联科技有限公司（下文简称“和盈”“我们”和“我们的”）深知隐私对您的重要性，并会尽全力保护您的隐私。请在向和盈提交个人或隐私数据之前，阅读、了解并同意本《隐私政策》（下文简称“本政策”）。',
    t_3:
      '本政策仅适用于和盈的消费类电子产品，包括显示或提及本政策的儿童手表、智能运动手表、车载产品、后视镜、计算机应用程序、工具、软件、网站以及服务。',
    t_4:
      '本政策阐述了和盈如何处理您的个人和隐私数据，并申明了和盈对保护隐私的承诺。本政策不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息由和盈在补充政策中发布。建议您阅读本政策和任何补充政策，充分了解和盈特定产品或服务的隐私政策。',
    t_5: '和盈会如何使用您的个人数据',
    t_6:
      '个人数据是指在单独使用或结合其他信息使用时能够确定个人身份的信息。此类数据会在您使用和盈产品或服务时被收集，包括姓名、出生日期、地址、身份证号码、电话号码、帐号、照片以及访问产品或服务的位置和日期。和盈仅会出于本政策所述目的收集和使用您的个人数据。下文举例说明了我们可能收集的个人数据以及我们如何使用此类数据：',
    t_7: '1.1 和盈收集的个人数据',
    t_8:
      '我们会收集某些个人数据，包括姓名、个人资料照片、电话号码、电子邮件地址、年龄和位置。下文举例说明了我们会收集的个人数据类型。',
    t_9:
      '您直接提交的数据：和盈的很多服务会让您创建帐户或个人资料。您可能需要提供帐户信息，例如电子邮件地址、收货地址、电话号码、产品信息、购买时间和付款方式。如果您使用和盈服务与朋友共享内容，我们可能需要您提供朋友的联系信息，包括姓名、个人资料照片、电话号码和电子邮件地址。和盈的某些服务可让您与他人通信并共享信息。所有此类通信都是安全的。如果您在使用和盈设备或应用程序时遇到任何错误，可选择向和盈发送错误详情。',
    t_10:
      '服务使用数据：我们会从您的设备收集系统和应用程序数据，包括设备名称、系统和应用程序版本、地区和语言设置、设备版本、设备识别码（IMEI、ESN、MEID 和 SN）、地理位置（例如设备定位所在区域 ID）、服务提供商网络 ID (PLMN)、使用习惯和 IP 地址。我们还会记录服务访问时间、搜索查询词条以及设备上的 Cookie 所保存的数据。',
    t_11:
      '第三方数据：在法律允许的情况下，我们还会从公用和商用来源获取有关您的数据。我们还会从第三方社交网络服务获取有关您的数据，例如您何时使用 微信、QQ、微博、Facebook 或 Twitter 帐户登录我们的网站。',
    t_12: '1.2 和盈会如何使用您的个人数据',
    t_13: '我们会将您的个人数据用于以下目的：',
    t_14:
      '履行订单；交付、激活或验证产品或服务；应您的要求进行变更；以及提供技术支持。',
    t_15:
      '在您明确同意的情况下，与您联系；向您发送有关您可能感兴趣的产品和服务的信息；邀请您参与和盈促销活动和市场调查；或向您发送营销信息。如果您不想接收此类信息，则可以随时退订。',
    t_16: '向您发送操作系统或应用程序更新和安装的通知。',
    t_17: '为您提供个性化用户体验和个性化内容，并激活售后服务。',
    t_18: '开展内部审计、数据分析和研究，改善我们的产品和服务。',
    t_19: '分析业务运营效率并衡量市场份额。',
    t_20: '改善客户通信并确保为客户提供安全优质的服务。',
    t_21: '在您选择向我们发送错误详情的情况下排查错误。',
    t_22: '同步、共享和存储您上传或下载的数据以及执行上传和下载所需的数据。',
    t_23: '改善我们的防损和反欺诈计划。',
    t_24: '1.3 基于位置的服务',
    t_25:
      '在访问某些基于位置的服务时（例如执行搜索、使用导航软件或查看某个位置的天气），和盈会收集、使用并处理您设备的准确位置或模糊位置。和盈需要这些位置信息才能提供上述服务。和盈会收集您设备的识别码（IMEI、ESN、MEID 和 SN）、设备类型、型号和实时位置数据（通过 GPS、WLAN 和服务提供商的网络 ID 获取）。',
    t_26: '和盈收集位置数据的目的在于提供和改善基于位置的产品和服务。',
    t_27:
      '我们会询问您要为哪些应用程序启用基于位置的服务。您可选择关闭设备上基于位置的服务，拒绝共享您的位置数据。如需详细了解如何禁用基于位置的服务，请联系您的网络运营商。',
    t_28: '1.4 收集和使用非识别性数据',
    t_29:
      '非识别性数据是指无法用于确定个人身份的数据。例如，和盈会收集汇总的统计数据，例如网站访问量。和盈收集此数据的目的在于了解用户如何使用自己的网站、产品和服务。借此，和盈可以改善自己的服务，更好地满足客户需求。和盈可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。',
    t_30:
      '我们会尽力隔离您的个人数据和非识别性数据，并单独使用这两种数据。如果个人数据掺杂了非识别性数据，依旧会被视作个人数据处理。',
    t_31: '和盈会如何使用 Cookie 和同类技术',
    t_32: '2.1 Cookie',
    t_33:
      '为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储用户偏好或购物车内的商品等数据。',
    t_34:
      '大多数大型网站或互联网服务提供商启用 Cookie 的目的在于改善用户体验。借助于 Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久 Cookie）。借助于 Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。',
    t_35:
      '如果某个网站不使用 Cookie，那么在用户每一次打开网页时，该网站都会将其视为新访客。例如，如果您登录某个网站后转到另一个网页，该网站就不会识别出您，而您会被再次注销。',
    t_36:
      '和盈不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。',
    t_37: '2.2 网站信标和像素标签',
    t_38:
      '除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，和盈向您发送的电子邮件可能含有链接至和盈网站内容的点击 URL。如果您点击该链接，和盈则会跟踪此次点击，帮助我们了解您的产品和服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从和盈的寄信名单中退订。',
    t_39: '和盈会如何披露您的个人数据',
    t_40:
      '和盈不会将您的个人数据出售给第三方。但是，和盈会在以下情况下披露您的个人数据：',
    t_41:
      '在获取明确同意的情况下披露：获得您的明确同意后，和盈会与其他方共享您的个人数据。',
    t_42:
      '披露给和盈的附属公司：您的个人数据可能会与和盈的附属公司共享。作为一项政策，我们只会披露必要的数据。',
    t_43:
      '披露给授权合作伙伴（下文简称“我们的合作伙伴”）：和盈的某些服务由我们的合作伙伴提供。和盈可能会与合作伙伴共享您的某些个人数据，以提供更好的客户服务和用户体验。例如，在您上网购买和盈产品时，和盈必须与物流服务提供商共享您的个人数据才能安排送货，或者安排合作伙伴提供服务。我们仅会出于特定、明确而合法的目的处理您的个人数据，并且只会披露提供服务所必要的数据。和盈的子公司或合作伙伴无权将共享的个人数据用于任何其他用途。',
    t_44:
      '基于法律或合理依据的披露：在法律、法律程序、诉讼或公共和政府主管部门有要求的情况下，和盈可能会披露您的个人数据。在某些管辖区，如果和盈牵涉到重组、合并或破产和清理诉讼，那么您的个人数据还会披露给交易方。和盈还会在存在合理需求的情况下披露您的数据，例如出于执行条款与条件以及保护客户的目的。',
    t_45: '如何访问或修改您的个人数据',
    t_46:
      '根据和盈的条款与条件，您可以随时管理自己的个人数据，例如您的帐户信息。您应确保提交的所有个人数据都准确无误。和盈会尽力维护个人数据的准确和完整，并及时更新这些数据。',
    t_47:
      '您有权访问自己的个人数据，某些法律例外情况除外。如果某些管辖区的隐私和信息法有所不同，我们则会遵守这些法律。',
    t_48:
      '您还有权删除或修改我们保管的关于您的任何个人信息。和盈会应您的要求删除或修改此类信息。如果我们有合理依据认为这些请求存在欺骗性、无法实行或者如果当地法律未规定访问权，我们则会拒绝处理请求。',
    t_49: '和盈会如何保护您的个人数据',
    t_50:
      '和盈重视个人数据的安全。我们采取业内标准做法来保护您的个人数据，防止数据遭到未经授权访问、披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人数据。例如，我们会使用加密技术确保数据的机密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。',
    t_51:
      '我们会采取一切合理可行的措施，确保未收集无关的个人数据。我们只会在达成本政策所述目的所需的期限内保留您的个人数据，除非需要延长保留期或受到法律的允许。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。',
    t_52: '和盈会如何处理儿童的个人数据',
    t_53:
      '我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户帐户。对于经父母同意而收集儿童个人数据的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或披露此数据。尽管当地法律和习俗对儿童的定义不同，但我们将不满 13 周岁的任何人均视为儿童。',
    t_54:
      '如果和盈发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人数据，则会设法尽快删除相关数据。',
    t_55: '第三方提供商及其服务',
    t_56:
      '为确保流畅的浏览体验，您可能会收到来自和盈及其合作伙伴外部的第三方（下文简称“第三方”）提供的内容或网络链接。和盈对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。',
    t_57:
      '和盈无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见这些第三方的隐私保护政策。',
    t_58: '您的个人数据如何在全球范围转移',
    t_59:
      '和盈通过遍布全球的资源和服务器提供产品和服务。这意味着，您的个人数据可能会被转移到您使用产品或服务所在国家/地区境外的其他管辖区，或者受到来自这些管辖区的访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，和盈会确保您的数据得到所有适用法律和法规要求的足够同等保护。例如，和盈会请求您对跨境转移个人数据的同意，或者在跨境数据转移之前实施数据匿名等安全举措。'
  },
  products: {
    noProduct: '该产品不存在，请查看其他产品'
  },
  serve: {
    desc:
      '对于在使用产品中遇到的问题，请您直接致电我公司用户服务电话：400-966-0001，以求更便捷有效处理。',
    title: '操作指南',
    classes: ['运动手表', '健康手环', '定位产品'],
    products: [
      'FERACE 3智能运动手表',
      'Jeep 智能全境界腕表',
      'FERACE-D3 手环',
      'T1 定位电话'
    ]
  }
};

const en = {
  nav: {
    watch: '运动手表',
    watchs: ['FERACE 3', 'Jeep'],
    bracelet: '健康手环',
    bracelets: ['D3 彩屏手环'],
    position: '定位产品',
    positions: ['4G定位电话 T1'],
    download: 'APP下载',
    news: '相关新闻',
    about: '关于我们',
    bbs: '产品论坛'
  },
  footer: {
    nav: {
      about: '关于我们',
      serve: '服务支持',
      weibo: '官方微博',
      weixin: '官方微信',
      jingdong: '京东自营店'
    },
    copyright:
      'Copyright © 2014-2018 HOIN Corporation, All Right Reserved ∣粤ICP备15063876号-2',
    privacyPolicy: '隐私政策'
  },
  home: {
    panels: [
      {
        h: 'FERACE 3',
        p: '智能运动手表'
      },
      {
        h: 'Jeep',
        p: '智能全境界腕表'
      },
      {
        h: 'FERACE',
        p: '4G定位电话 T1'
      }
    ],
    slides: [
      {
        name: 'Jeep 智能全境界腕表',
        desc: [
          '专业运动手表，支持跑步、徒步、游泳、骑行等专业运动模式',
          '支持4G全网通，独立插卡',
          '支持支付、导航等、语音、在线音乐、在线翻译等扩展智能化应用',
          '高通骁龙Wear 2100芯片',
          '1.30英寸360*360 AMOLED屏幕',
          '460mAh聚合物锂电池'
        ]
      },
      {
        name: 'FERACE 3 智能运动手表',
        desc: [
          '专业运动手表，支持跑步、徒步、游泳、登山、骑行等专业运动模式',
          '支持 4G 全网通，独立插卡',
          '支持 4G 穿戴版支付宝、在线音乐等运动场景下的智能应用',
          '高通骁龙 Wear 2100 芯片',
          '1.39 英寸 360*360 AMOLED 屏幕',
          '665mAh 聚合物锂电池'
        ]
      },
      {
        name: 'D 3 彩屏手环',
        desc: [
          '消息实时提醒：来电提醒、微信提醒、QQ提醒',
          '24小时心率检测',
          '专业睡眠监测',
          '卡路里、计步、距离、血压、血氧、久坐提醒、查找手机、抬腕亮屏、闹钟、秒表、遥控拍照',
          '0.96 英寸高清彩屏'
        ]
      }
    ]
  },
  about: {
    title_1: '关于我们',
    title_2: '联系我们',
    introduction: [
      '和盈互联是一家运动健康高科技公司，致力于用科技使人健康生活。用户通过智能可穿戴设备，科学精准的监测自己的运动、睡眠、心率、慢病等健康数据；同时，用户基于公司iService平台提供的AI辅助分析和健康指导，形成个性化的运动和饮食健康解决方案，且通过iService平台进行运动和饮食自我管理，最终构建用户个性化的运动健康生活方式。',
      '公司核心研发和运营团队主要来自海信、光聚医疗、百度、清华伯克利、加拿大七橡树，在运动健康智能硬件、手表安卓系统深度开发及运动算法上有核心技术积累，在运营商渠道和保险健康渠道有多年合作沉淀。公司未来发展重点：为用户提供客制化、专业化的实时健康监测的智能穿戴产品，及用户可进行健康生活自我管理的iSERVICE健康管理平台。',
      '2017年6月，公司成功推出了全球首款4G全网通智能运动手表及iSports 1.0 核心运动算法，解决了用户抛开手机去运动问题，引领智能穿戴从1.0时代进入2.0时代。2018年6月，公司与Jeep联合推出全球最薄4G全网通智能运动手表及iSports 2.0核心运动算法，解决了用户专业运动的问题。2018年7月，公司与平安好医生达成战略合作，为平安好医生提供运动健康智能硬件及硬件接入服务，开启运动健康智能硬件+保险公司健康保险业务合作新模式。2018年12月，公司成功中标中国移动首款自主品牌4G智能运动手表定制项目，开启与中国移动的深度战略合作。',
      '公司是美国高通、阿里云计算、科大讯飞、平安好医生、飞亚达、清华伯克利、加拿大七橡树战略技术合作伙伴；是Jeep、飞亚达、中国电信、中国移动、京东、平安好医生战略品牌合作伙伴；是京东、中国电信、中国移动、中国联通、迪信通、飞亚达等战略渠道合作伙伴；是中石油物流信息化认证资质供应商。',
      '愿景：成为全球顶级的运动健康高科技公司',
      '使命：用科技使人健康生活',
      '价值观：诚信、进取、合作、和赢'
    ],
    contact: [
      {
        name: '服务热线',
        text: '400-966-0001'
      },
      {
        name: '市场商务合作',
        text: 'yibx@hoinnet.com'
      },
      {
        name: '销售渠道合作',
        text: 'zhuxj@hoinnet.com'
      },
      {
        name: '地址',
        text: '深圳市宝安区宝安大道4004号旭生大厦16层'
      }
    ]
  },
  download: {
    apps: [
      {
        name: 'FERACE 运动',
        text:
          'FERACE 运动手表配套应用。可管理运动数据、下载表盘及各种手表应用。'
      },
      {
        name: 'JeepWatches',
        text: 'Jeep 运动手表配套应用，可管理运动数据、下载表盘及各种手表应用。'
      },
      {
        name: 'FERACE 健康',
        text: 'FERACE 系列手环配套应用，可进行设备设置，及运动健康数据的管理。'
      },
      {
        name: 'FERACE 定位',
        text: 'FERACE 定位电话配套应用，可进行设备设置，远程定位。'
      }
    ]
  },
  news: { readmore: '阅读全文' },
  privacy: {
    t_1: '尊敬的用户：',
    t_2:
      '深圳市和盈互联科技有限公司（下文简称“和盈”“我们”和“我们的”）深知隐私对您的重要性，并会尽全力保护您的隐私。请在向和盈提交个人或隐私数据之前，阅读、了解并同意本《隐私政策》（下文简称“本政策”）。',
    t_3:
      '本政策仅适用于和盈的消费类电子产品，包括显示或提及本政策的儿童手表、智能运动手表、车载产品、后视镜、计算机应用程序、工具、软件、网站以及服务。',
    t_4:
      '本政策阐述了和盈如何处理您的个人和隐私数据，并申明了和盈对保护隐私的承诺。本政策不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息由和盈在补充政策中发布。建议您阅读本政策和任何补充政策，充分了解和盈特定产品或服务的隐私政策。',
    t_5: '和盈会如何使用您的个人数据',
    t_6:
      '个人数据是指在单独使用或结合其他信息使用时能够确定个人身份的信息。此类数据会在您使用和盈产品或服务时被收集，包括姓名、出生日期、地址、身份证号码、电话号码、帐号、照片以及访问产品或服务的位置和日期。和盈仅会出于本政策所述目的收集和使用您的个人数据。下文举例说明了我们可能收集的个人数据以及我们如何使用此类数据：',
    t_7: '1.1 和盈收集的个人数据',
    t_8:
      '我们会收集某些个人数据，包括姓名、个人资料照片、电话号码、电子邮件地址、年龄和位置。下文举例说明了我们会收集的个人数据类型。',
    t_9:
      '您直接提交的数据：和盈的很多服务会让您创建帐户或个人资料。您可能需要提供帐户信息，例如电子邮件地址、收货地址、电话号码、产品信息、购买时间和付款方式。如果您使用和盈服务与朋友共享内容，我们可能需要您提供朋友的联系信息，包括姓名、个人资料照片、电话号码和电子邮件地址。和盈的某些服务可让您与他人通信并共享信息。所有此类通信都是安全的。如果您在使用和盈设备或应用程序时遇到任何错误，可选择向和盈发送错误详情。',
    t_10:
      '服务使用数据：我们会从您的设备收集系统和应用程序数据，包括设备名称、系统和应用程序版本、地区和语言设置、设备版本、设备识别码（IMEI、ESN、MEID 和 SN）、地理位置（例如设备定位所在区域 ID）、服务提供商网络 ID (PLMN)、使用习惯和 IP 地址。我们还会记录服务访问时间、搜索查询词条以及设备上的 Cookie 所保存的数据。',
    t_11:
      '第三方数据：在法律允许的情况下，我们还会从公用和商用来源获取有关您的数据。我们还会从第三方社交网络服务获取有关您的数据，例如您何时使用 微信、QQ、微博、Facebook 或 Twitter 帐户登录我们的网站。',
    t_12: '1.2 和盈会如何使用您的个人数据',
    t_13: '我们会将您的个人数据用于以下目的：',
    t_14:
      '履行订单；交付、激活或验证产品或服务；应您的要求进行变更；以及提供技术支持。',
    t_15:
      '在您明确同意的情况下，与您联系；向您发送有关您可能感兴趣的产品和服务的信息；邀请您参与和盈促销活动和市场调查；或向您发送营销信息。如果您不想接收此类信息，则可以随时退订。',
    t_16: '向您发送操作系统或应用程序更新和安装的通知。',
    t_17: '为您提供个性化用户体验和个性化内容，并激活售后服务。',
    t_18: '开展内部审计、数据分析和研究，改善我们的产品和服务。',
    t_19: '分析业务运营效率并衡量市场份额。',
    t_20: '改善客户通信并确保为客户提供安全优质的服务。',
    t_21: '在您选择向我们发送错误详情的情况下排查错误。',
    t_22: '同步、共享和存储您上传或下载的数据以及执行上传和下载所需的数据。',
    t_23: '改善我们的防损和反欺诈计划。',
    t_24: '1.3 基于位置的服务',
    t_25:
      '在访问某些基于位置的服务时（例如执行搜索、使用导航软件或查看某个位置的天气），和盈会收集、使用并处理您设备的准确位置或模糊位置。和盈需要这些位置信息才能提供上述服务。和盈会收集您设备的识别码（IMEI、ESN、MEID 和 SN）、设备类型、型号和实时位置数据（通过 GPS、WLAN 和服务提供商的网络 ID 获取）。',
    t_26: '和盈收集位置数据的目的在于提供和改善基于位置的产品和服务。',
    t_27:
      '我们会询问您要为哪些应用程序启用基于位置的服务。您可选择关闭设备上基于位置的服务，拒绝共享您的位置数据。如需详细了解如何禁用基于位置的服务，请联系您的网络运营商。',
    t_28: '1.4 收集和使用非识别性数据',
    t_29:
      '非识别性数据是指无法用于确定个人身份的数据。例如，和盈会收集汇总的统计数据，例如网站访问量。和盈收集此数据的目的在于了解用户如何使用自己的网站、产品和服务。借此，和盈可以改善自己的服务，更好地满足客户需求。和盈可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。',
    t_30:
      '我们会尽力隔离您的个人数据和非识别性数据，并单独使用这两种数据。如果个人数据掺杂了非识别性数据，依旧会被视作个人数据处理。',
    t_31: '和盈会如何使用 Cookie 和同类技术',
    t_32: '2.1 Cookie',
    t_33:
      '为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储用户偏好或购物车内的商品等数据。',
    t_34:
      '大多数大型网站或互联网服务提供商启用 Cookie 的目的在于改善用户体验。借助于 Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久 Cookie）。借助于 Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。',
    t_35:
      '如果某个网站不使用 Cookie，那么在用户每一次打开网页时，该网站都会将其视为新访客。例如，如果您登录某个网站后转到另一个网页，该网站就不会识别出您，而您会被再次注销。',
    t_36:
      '和盈不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。',
    t_37: '2.2 网站信标和像素标签',
    t_38:
      '除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，和盈向您发送的电子邮件可能含有链接至和盈网站内容的点击 URL。如果您点击该链接，和盈则会跟踪此次点击，帮助我们了解您的产品和服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从和盈的寄信名单中退订。',
    t_39: '和盈会如何披露您的个人数据',
    t_40:
      '和盈不会将您的个人数据出售给第三方。但是，和盈会在以下情况下披露您的个人数据：',
    t_41:
      '在获取明确同意的情况下披露：获得您的明确同意后，和盈会与其他方共享您的个人数据。',
    t_42:
      '披露给和盈的附属公司：您的个人数据可能会与和盈的附属公司共享。作为一项政策，我们只会披露必要的数据。',
    t_43:
      '披露给授权合作伙伴（下文简称“我们的合作伙伴”）：和盈的某些服务由我们的合作伙伴提供。和盈可能会与合作伙伴共享您的某些个人数据，以提供更好的客户服务和用户体验。例如，在您上网购买和盈产品时，和盈必须与物流服务提供商共享您的个人数据才能安排送货，或者安排合作伙伴提供服务。我们仅会出于特定、明确而合法的目的处理您的个人数据，并且只会披露提供服务所必要的数据。和盈的子公司或合作伙伴无权将共享的个人数据用于任何其他用途。',
    t_44:
      '基于法律或合理依据的披露：在法律、法律程序、诉讼或公共和政府主管部门有要求的情况下，和盈可能会披露您的个人数据。在某些管辖区，如果和盈牵涉到重组、合并或破产和清理诉讼，那么您的个人数据还会披露给交易方。和盈还会在存在合理需求的情况下披露您的数据，例如出于执行条款与条件以及保护客户的目的。',
    t_45: '如何访问或修改您的个人数据',
    t_46:
      '根据和盈的条款与条件，您可以随时管理自己的个人数据，例如您的帐户信息。您应确保提交的所有个人数据都准确无误。和盈会尽力维护个人数据的准确和完整，并及时更新这些数据。',
    t_47:
      '您有权访问自己的个人数据，某些法律例外情况除外。如果某些管辖区的隐私和信息法有所不同，我们则会遵守这些法律。',
    t_48:
      '您还有权删除或修改我们保管的关于您的任何个人信息。和盈会应您的要求删除或修改此类信息。如果我们有合理依据认为这些请求存在欺骗性、无法实行或者如果当地法律未规定访问权，我们则会拒绝处理请求。',
    t_49: '和盈会如何保护您的个人数据',
    t_50:
      '和盈重视个人数据的安全。我们采取业内标准做法来保护您的个人数据，防止数据遭到未经授权访问、披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人数据。例如，我们会使用加密技术确保数据的机密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。',
    t_51:
      '我们会采取一切合理可行的措施，确保未收集无关的个人数据。我们只会在达成本政策所述目的所需的期限内保留您的个人数据，除非需要延长保留期或受到法律的允许。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。',
    t_52: '和盈会如何处理儿童的个人数据',
    t_53:
      '我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户帐户。对于经父母同意而收集儿童个人数据的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或披露此数据。尽管当地法律和习俗对儿童的定义不同，但我们将不满 13 周岁的任何人均视为儿童。',
    t_54:
      '如果和盈发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人数据，则会设法尽快删除相关数据。',
    t_55: '第三方提供商及其服务',
    t_56:
      '为确保流畅的浏览体验，您可能会收到来自和盈及其合作伙伴外部的第三方（下文简称“第三方”）提供的内容或网络链接。和盈对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。',
    t_57:
      '和盈无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见这些第三方的隐私保护政策。',
    t_58: '您的个人数据如何在全球范围转移',
    t_59:
      '和盈通过遍布全球的资源和服务器提供产品和服务。这意味着，您的个人数据可能会被转移到您使用产品或服务所在国家/地区境外的其他管辖区，或者受到来自这些管辖区的访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，和盈会确保您的数据得到所有适用法律和法规要求的足够同等保护。例如，和盈会请求您对跨境转移个人数据的同意，或者在跨境数据转移之前实施数据匿名等安全举措。'
  },
  products: {
    noProduct: '该产品不存在，请查看其他产品'
  },
  serve: {
    desc:
      '对于在使用产品中遇到的问题，请您直接致电我公司用户服务电话：400-966-0001，以求更便捷有效处理。',
    title: '操作指南',
    classes: ['运动手表', '健康手环', '定位产品'],
    products: [
      'FERACE 3智能运动手表',
      'Jeep 智能全境界腕表',
      'FERACE-D3 手环',
      'T1 定位电话'
    ]
  }
};

const i18n = new VueI18n({
  locale: language,
  messages: {
    // zh: require('./lang/zh'),
    // en: require('./lang/en')
    zh,
    en
  }
});

export default i18n;

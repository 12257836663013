import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home/Home.vue';

Vue.use(Router);

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/download',
      name: 'download',
      component: () => import('./views/Download/Download.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News/News.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About/About.vue')
    },
    {
      path: '/serve',
      name: 'serve',
      component: () => import('./views/Serve/Serve.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/Privacy/Privacy.vue')
    },
    {
      path: '/product/:id',
      name: 'product',
      component: () => import('./views/Product/Product.vue')
    }
  ]
});

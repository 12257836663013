<template>
  <div id="app" class="pc" v-cloak>
    <v-header></v-header>
    <div class="content">
      <div class="wrapper">
        <router-view></router-view>
      </div>
    </div>
    <v-footer></v-footer>

    <transition name="goTop">
      <a class="goTop" v-if="goTopButton" @click="goTop"></a>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
    'v-header': () => import("@/components/Header/Header"),
    'v-footer': () => import("@/components/Footer/Footer"),
  },
  data() {
    return {
      goTopButton: false
    }
  },
  methods: {
    goTop() {
      const timer = setInterval(() => {
        const scrollTop = document.documentElement.scrollTop
        const speed = Math.floor(-scrollTop / 6)
        if (scrollTop == 0) {
          clearInterval(timer)
        }
        document.documentElement.scrollTop = scrollTop + speed
      }, 10)
    }
  },
  mounted() {
    window.onscroll = () => {
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop > 500) {
        this.goTopButton = true
      } else {
        this.goTopButton = false
      }
    }
  }
}
</script>

<style lang="stylus">
html, body, h1, h2, h3, h4, h5, h6, p, ul, li, div, dl, dt, dd
  margin 0
  padding 0
  box-sizing border-box

body
  font 16px / 1.231 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', Arial, Verdana, 'Microsoft Yahei'

div, li, dt, dd, p, pre, td, th
  word-wrap break-word
  word-break break-all

input, textarea, button, a
  -webkit-appearance none
  -webkit-tap-highlight-color transparent
  border none
  outline none

a
  text-decoration none
  -webkit-user-select none

table
  border-collapse collapse

em, i
  font-style normal

ol, ul
  list-style none

li
  list-style-position outside

img, fieldset
  border none

input
  outline none

[v-cloak]
  display none

.container
  max-width 1200px
  margin 0 auto

#app.pc
  min-width 1200px

.goTop
  background #bbb
  position fixed
  right 50px
  bottom 100px
  width 60px
  height 60px
  border-radius 50%
  cursor pointer

  &:after
    display block
    content ''
    width 16px
    height 16px
    border 3px solid
    border-color #fff #fff transparent transparent
    position absolute
    top 25px
    left 19px
    transform rotate(-45deg)

.goTop-enter-active, .goTop-leave-active
  transition opacity 1s

.goTop-enter, .goTop-leave-to
  opacity 0

span.swiper-pagination-bullet
  background #fff
  opacity .6
  border-radius 8px
  transition .8s
  outline none

span.swiper-pagination-bullet-active
  width 30px
  background #f80
  opacity 1
</style>
